@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

body {
  margin: 0;
}

div, a, img, span {
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
}

.hero {
  height: 100vh;
}

.sd {
  font-size: 20px;
}

.App {
  text-align: center;
}


.projects-date {
  text-align: left;
  color: rgb(171, 171, 171);
}

.title {
  display: block;
  text-align: left;
  padding: 90px;
  font-size: 20px;

  font-family: 'Source Sans Pro', sans-serif;
  font-weight: bold;
}

.text-content {
  display: flex;
  padding-top: 200px;
}

.content-home {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  width: 1061px;
}

.banner-projects {
  display: flex;
  justify-content: space-between;
  padding-top: 60px;
  width: 900px;
}

.home-title {
  text-align: left;
  font-size: 50px;
  font-weight: bold;
}

.home-title-bottom {
  background: -webkit-linear-gradient(200deg, #2188ff 0%, #a24eda 100%);
-webkit-background-clip: text;
background-clip: text;
-webkit-text-fill-color: transparent; 
}

.content-home-center {
  display: flex;
  justify-content: center;
  text-align: center;
}

.content-banner-center {
  display: flex;
  justify-content: center;
  width: 100%;
}

.home-image img {
  width: 500px;
  padding-left: 0%;
  padding-bottom: 50%;
  padding-right: 20%;
}

.icon {
  color: black;
}

.icon i {
  font-size: 28px;
}

.projects {
  padding-bottom: 200px;
  background-color: #f7f7f7;
}

.projects-title-content {
  padding-top: 100px;
  font-size: 100px;
  font-weight: bold;
  font-family: 'Russo One', sans-serif;
  background: -webkit-linear-gradient(-70deg, #8490ff 0%, #453cd6 100%);
-webkit-background-clip: text;
background-clip: text;
-webkit-text-fill-color: transparent; 
}

.projects-image img {
  width: 500px;
}

.icon-bar {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-right: 70px;
}

.projects-box {
  display: flex;
  justify-content: center;

  margin-top: 100px;
}

.projects-img img {
  width: 700px;
  height: 400px;
  border-radius: 20px;
  object-fit: cover;
}

.projects-content {
  padding-top: 10px;
   background-color: #f7f7f7;
}

.projects-icon {
  display: flex;
  justify-content: center;
}

.projects-icon img{
  padding: 15px;
  border-radius: 20px;
  width: 50px;
  height: 50px;
}

.content-space {
  padding: 50px;
}

.projects-title {
  padding-top: 10px;
  font-size: 50px;
  font-weight: bold;
}

.projects-text p {
  color: grey;
  text-align: left;
  font-size: 20px;
  width: 436px;
}

.more {
  text-decoration: none;
  color: #453cd6;
  font-weight: bold;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
}

.more:hover {
  padding-right: 10px;
}

footer {
  background-color: #64aaff;
  display: flex;
  justify-content: space-between;
}

.footer-image img {
  width: 400px;
}


.footer-text .icon-main {
  margin: 100px;
  display: flex;
  margin-bottom: 0px;
}

.footer-text .icon-main div {
  padding: 20px;
}

.footer-text .icon-main div a img {
  width: 30px;
  filter: invert(100%);
}

.cft {
  color: white;
  margin-left: 120px;
  text-align: left;
}

.cft div {
  display: flex;
}

.cft a {
  color: white;
}

.footer-text a {
  display: block;
  color: #f7f7f7;
}

.footer-text .icon i {
  font-size: 40px;
}

.about {
  position:sticky;
  z-index: 100;
  padding: 100px;
  display: flex;
  justify-content: center;
  background: linear-gradient(200deg, #2188ff 0%, #a24eda 100%);
}

.about p {
  z-index: 100;
  text-align: left;
  color: white;
  font-size: 25px;
  font-weight: bold;
  width: 80%;
}

.about a {
  color: #f7f7f7;
  transition: 0.5s;
}
.about a:hover {
  color: #cecece;
}

@media screen and (max-width: 1220px) {

  .projects-content {
    padding-top: 10px;
     background-color: #a03939;
     background-color: #f7f7f7;
     display: flex;
     justify-content: center;
     text-align: center;
  }

  .projects-text p {
    color: grey;
    text-align: center;
    font-size: 20px;
    width: 100%;
  }

  .projects-title {
    padding-top: 10px;
    font-size: 50px;
    font-weight: bold;
    width: 100%;
  }

  .projects-box {
    display: block;
    justify-content: center;
  
    margin-top: 100px;
  }

  .footer-image {
    display: none;
  }

  footer {
    padding-bottom: 100px;
  }

  .banner-projects {
    display: block;
  }

  .home-title {
    text-align: center;
  }

  .content-home {
    display: block;
  }

  .text-content {
    display: block;
    justify-content: center;
    padding-top: 0;
  }

  .icon-bar {
    justify-content: center;
    flex-direction: unset;
  }

  .content-home {
    margin-top: 50px;
    text-align: center;
    justify-content: center;
  }

  .home-image img {
    padding-top: 100px;
    padding-left: 0;
    padding-bottom: 0;
    padding-right: 0;
    width: 70%;
  }

  .hero {
    height: 1000px;
  }

  .projects-date {
    text-align: center;
  }

  .icon {
    display: none;
  }

  .projects-img img {
    width: 90%;
    height: 250px;
    border-radius: 20px;
    object-fit: cover;
  }

  .footer-text {
    text-align: center;
    justify-content: center;
  }

  .cft div {
    padding-top: 20px;
    display: block;
  }

  .about {
    position: relative;
    z-index: 100;
    padding: 20px;
  }

  .about p {
    font-size: 25px;
    font-weight: bold;
    width: 100%;
    line-height: 35px;
  }

  .content-banner-center {
    margin-top: 200px;
  }
}

@media screen and (max-width: 550px) {

  .projects-image img {
    width: 80%;
  }

  .projects-title-content {
    font-size: 60px;
  }

  .cft {
    text-align: center;
    margin-left: 0px;
  }

  .footer-text .icon-main {
    text-align: center;
    justify-content: center;
  }

  .footer-text {
    width: 100%;
    text-align: center;
    justify-content: center;
  }  
}

@media screen and (max-width: 400px) {

  .projects-image img {
    width: 80%;
  }

  .projects-title-content {
    font-size: 60px;
  }
}